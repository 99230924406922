import { environment } from '../../../environments/environment';
import { ArchiveState } from './archive.type';

export class Archive {
  public static rootPath: string = '/videos';

  public static internalScrollToFragment: string = '';

  public static propertySearch: string = 'search';
  public static propertyCompetition: string = 'page';
  public static propertyTeam: string = 'team';

  public static defaultCompetition: string = 'all';
  public static defaultTeam: string = 'all';

  public static playlistLimit: number = environment.playlistPageSize;

  public static separator: string = ',';

  public static archiveState: ArchiveState = {
    data: {
      success: {
        data: [],
        pagingData: {
          itemCount: 0,
          pageCount: 0,
          pageIndex: -1,
          pageSize: Archive.playlistLimit,
          totalCount: 0,
        }
      },
      error: false,
    },
    isLoading: false,
  };

  public static getValueFromLabel(label: string): string {
    return label
      .toLocaleLowerCase()
      .split('&')
      .join('')
      .replace('  ', ' ')
      .split(' ')
      .join('-');
  }
}
