import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import SimpleBar from 'simplebar';
import anywhereTeams from '../../../../../../_assets/json/anywhere/teams.json';
import { GridFilter } from '../../../../../modules/filter-bar/search-bar.type';
import { FaFavTeamsService } from '../../../../services/fa-favteams.service';
import { ModalService } from '../../../../services/modal.service';
import { FavTeam } from '../../../../types/FavTeamsApiResponse.type';
import { FaFavTeamModalFormStatus } from './fa-fav-team-modal-form-status.enum';

@Component({
  selector: 'app-fa-fav-team-modal',
  templateUrl: './fa-fav-team-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaFavTeamModalComponent implements OnInit, AfterViewChecked, OnDestroy {
  private static modalId: string = 'fa-fav-teams';
  private static modalBodyId: string = `${FaFavTeamModalComponent.modalId}-modal-body`;
  private static minTeams: number = 1;
  private static maxTeams: number = 2;

  public FaFavTeamModalFormStatusEnum = FaFavTeamModalFormStatus;

  @ViewChild('modalRef', { static: true }) private modalRef: TemplateRef<any>;
  private ngbModalRef: NgbModalRef;
  private modalBodyRef: HTMLElement;
  private ngbModalScrollBar: SimpleBar;

  public localAnywhereTeam: Array<GridFilter>;
  public faFavTeamFormStatus: FaFavTeamModalFormStatus;
  public faFavTeamLastClick: GridFilter;

  private pristineFavTeams: Array<FavTeam>;
  private selectedFavTeams: Array<FavTeam>;

  private openModalSubscription: Subscription;
  private faFavTeamsSubscription: Subscription;
  private faSetFavTeamsSubscription: Subscription;

  constructor(
    private ngbModalService: NgbModal,
    private modalService: ModalService,
    private faFavTeamsService: FaFavTeamsService,
  ) {
    this.faFavTeamLastClick = null;
    this.ngbModalRef = null;

    this.initLocalAnywhereTeams();

    this.resetModal();
  }

  ngOnInit() {
    this.subscribeToModalService();

    this.subscribeToFavTeamsUpdates();
  }

  ngOnDestroy() {
    this.openModalSubscription.unsubscribe();
    this.faFavTeamsSubscription.unsubscribe();

    if (this.faSetFavTeamsSubscription) {
      this.faSetFavTeamsSubscription.unsubscribe();
    }
  }

  ngAfterViewChecked(): void {
    this.initModalScroll();
  }

  private initLocalAnywhereTeams() {
    this.localAnywhereTeam = (anywhereTeams as unknown as Array<GridFilter>)
      .filter((anywhereTeam: GridFilter) => {
        return anywhereTeam.value !== '';
      })
      .sort((anywhereTeam1: GridFilter, anywhereTeam2: GridFilter) => {
        const label1: string = anywhereTeam1.label;
        const label2: string = anywhereTeam2.label;
        return label1.localeCompare(label2);
      });

    this.localAnywhereTeam
      .forEach((anywhereTeam: GridFilter) => {
        anywhereTeam.value = anywhereTeam.value.startsWith('(')
          ? anywhereTeam.value.substring(1, anywhereTeam.value.length - 1)
          : anywhereTeam.value;
      });
  }

  private initModalScroll(): void {
    if (!this.ngbModalScrollBar && !this.modalBodyRef && this.ngbModalRef) {
      this.modalBodyRef = document.getElementById(FaFavTeamModalComponent.modalBodyId);
      if (this.modalBodyRef) {
        this.ngbModalScrollBar = new SimpleBar(this.modalBodyRef, {autoHide: false});
      }
    }
  }

  private resetModal() {
    this.ngbModalScrollBar = null;
    this.modalBodyRef = null;
    this.faFavTeamFormStatus = FaFavTeamModalFormStatus.init;
    this.pristineFavTeams = new Array();
    this.selectedFavTeams = new Array();

    if (this.faFavTeamsSubscription) {
      this.faFavTeamsSubscription.unsubscribe();
      this.subscribeToFavTeamsUpdates();
    }
  }

  private subscribeToModalService() {
    this.openModalSubscription = this.modalService
      .getOpenModalObservable()
      .subscribe((id: string) => {
        if (id && id === FaFavTeamModalComponent.modalId) {
          this.open();
        }
      });
  }

  private subscribeToFavTeamsUpdates() {
    this.faFavTeamsSubscription = this.faFavTeamsService
      .getFavTeamsObservable()
      .subscribe((favTeams: Array<FavTeam> = []) => {
        this.pristineFavTeams = [...favTeams];
        // filter list from server to reflect only teams that we actually know about
        this.localAnywhereTeam
          .forEach((anywhereTeam: GridFilter) => {
            const i: number = this.findFavTeamIndex(anywhereTeam, this.pristineFavTeams);
            if (i > -1) {
              this.addFavTeam(anywhereTeam);
            }
          });
      });
  }

  /**
   * Get number of maximum allowed teams
   */
  public getMaxTeams(): number {
    return FaFavTeamModalComponent.maxTeams;
  }

  /**
   * Is max number of teams selected
   */
  public maxTeamsSelected(): boolean {
    return this.selectedFavTeams.length === FaFavTeamModalComponent.maxTeams;
  }

  /**
   * Check if team is selected
   * @param anywhereTeam
   */
  public isTeamSelected(anywhereTeam: GridFilter): boolean {
    return this.findFavTeamIndex(anywhereTeam, this.selectedFavTeams) > -1;
  }

  /**
   * Check if go button is enabled
   * @param anywhereTeam
   */
  public isGoButtonEnabled(): boolean {
    return this.faFavTeamFormStatus === FaFavTeamModalFormStatus.valid;
  }

  /**
   * Toggle selected team
   * @param anywhereTeam
   */
  public toggleTeam(anywhereTeam: GridFilter): boolean {
    let teamAdded: boolean = false;

    this.faFavTeamLastClick = null;
    const i = this.findFavTeamIndex(anywhereTeam, this.selectedFavTeams);

    if (i === -1) {
      this.faFavTeamLastClick = anywhereTeam;
      this.addFavTeam(anywhereTeam);
      teamAdded = true;
    } else {
      this.removeFavTeam(anywhereTeam);
    }

    if (this.selectedFavTeams.length < FaFavTeamModalComponent.minTeams) {
      this.faFavTeamFormStatus = FaFavTeamModalFormStatus.moreTeamsNeeded;
    } else if (this.selectedFavTeams.length > FaFavTeamModalComponent.maxTeams) {
      this.faFavTeamFormStatus = FaFavTeamModalFormStatus.tooManyTeams;
    } else {
      this.faFavTeamFormStatus = FaFavTeamModalFormStatus.valid;
    }

    return teamAdded;
  }

  public saveChoices() {
    if (this.faSetFavTeamsSubscription) {
      this.faSetFavTeamsSubscription.unsubscribe();
    }

    this.faSetFavTeamsSubscription = this.faFavTeamsService
      .saveFavTeams(this.selectedFavTeams)
      .subscribe({
        next: (i: number) => {
          if (i > 0) {
            this.close();
          }
        },
        error: (i: number) => {
          this.faFavTeamFormStatus = FaFavTeamModalFormStatus.networkError;
        },
      });
  }

  private open() {
    if (this.ngbModalRef) {
      this.ngbModalRef.close();
    }

    this.ngbModalRef = this.ngbModalService.open(this.modalRef, { windowClass: 'fa-fav-team-modal' });
    this.ngbModalRef.result
      .then((result) => {
        this.resetModal();
      }, (reason) => {
        this.resetModal();
      });
  }

  private close() {
    this.ngbModalRef.close();
    this.ngbModalRef = null;
    this.modalService.closeModal(FaFavTeamModalComponent.modalId);
  }

  /**
   * Add favorite team to internal list
   * @param teamName Team Name to add
   */
  private addFavTeam(team: GridFilter) {
    this.selectedFavTeams.push({
      club_id: team.external_value,
      club_name: team.label,
    });
  }

  /**
   * Remove favorite team to internal list
   * @param teamName Team Name to remove
   */
  private removeFavTeam(team: GridFilter) {
    const i: number = this.findFavTeamNameIndex(team, this.selectedFavTeams);
    this.selectedFavTeams.splice(i, 1);
  }

  /**
   * Find favorite team in by club_name in given array
   * @param needle club_name
   * @param haystack where to search
   */
  private findFavTeamNameIndex(needle: GridFilter, haystack: Array<FavTeam>): number {
    const club_id: string = needle.external_value;
    const i: number = haystack.findIndex((favTeam: FavTeam) => {
      return club_id === favTeam.club_id;
    });
    return i;
  }

  /**
   * Find favorite team in by anywhereTeam label in given array
   * @param needle anywhereTeam
   * @param haystack where to search
   */
  private findFavTeamIndex(needle: GridFilter, haystack: Array<FavTeam>): number {
    if (needle && needle.value) {
      return this.findFavTeamNameIndex(needle, haystack);
    }
    return -1;
  }
}
