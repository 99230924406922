import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UAParser } from 'ua-parser-js';
import { WpInstallPrompt } from '../../core/types/wordpress/components/wp-common-fields.type';

declare var navigator;

@Component({
  selector: 'app-smart-banner',
  templateUrl: './smart-banner.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartBannerComponent implements OnInit {

  @Input() installPrompt: WpInstallPrompt;

  private daysHidden: number;
  private daysReminder: number;

  private today: Date;
  private showAgain: Date;

  public showAndroid: boolean;
  public showIOS: boolean;

  public linkAndroid: string;
  public linkIOS: string;

  public icon: string;

  private html: HTMLHtmlElement;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    this.showAndroid = false;
    this.showIOS = false;

    this.html = this.document.getElementsByTagName('html')[0];
  }

  public dismissBanner(): void {
    this.showAgain = this.startOfDay(this.addDays(this.today, this.daysHidden));

    this.dismiss();
  }

  public dismissCTA(): void {
    this.showAgain = this.startOfDay(this.addDays(this.today, this.daysReminder));

    this.dismiss();
  }

  private dismiss() {
    this.html.classList.remove('smartbanner-show');

    this.showAndroid = false;
    this.showIOS = false;

    this.saveSmartBanner();
    this.changeDetectorRef.markForCheck();
  }

  ngOnInit() {
    this.installPrompt = this.installPrompt || {
      cta_text: '',
      days_hidden: 15,
      days_reminder: 90,
      display_name: '',
      title: '',
      stores: {
        android_id: '',
        android_price: '',
        android_text: '',
        ios_id: '',
        ios_price: '',
        ios_text: '',
      },
    };

    this.init();

    if (this.isEnabled()) {

      if (this.isAndroid()) {
        this.initAfterEnabled();

        this.showAndroid = true;

        this.linkAndroid = 'http://play.google.com/store/apps/details?id=' + this.installPrompt.stores.android_id;
      }
      if (this.isIOS()) {
        this.initAfterEnabled();

        this.showIOS = true;

        const userLangAttribute = navigator.language || navigator.userLanguage || navigator.browserLanguage;
        const userLang = userLangAttribute.slice(-2) || 'us';

        this.linkIOS = `http://itunes.apple.com/${userLang}/app/${this.installPrompt.stores.ios_id}?mt=8`;
      }
    }

    this.changeDetectorRef.markForCheck();
  }

  private init() {
    this.daysHidden = parseInt(this.installPrompt.days_hidden as any) || 15;
    this.daysReminder = parseInt(this.installPrompt.days_reminder as any) || 90;

    this.today = this.endOfDay(new Date());

    this.showAgain = this.startOfDay(this.today);

    this.icon = 'favicon.ico';

    this.restoreSmartBanner();
  }

  private initAfterEnabled() {
    this.html.classList.add('smartbanner-show');

    const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
    const icon = document.querySelector('link[rel="icon"][type="image/x-icon"]');

    let href = 'favicon.ico';
    try {
      href = (appleTouchIcon || icon).getAttribute('href');
    } catch (ex) {
      // ignore
    }
    this.icon = href;
  }

  private saveSmartBanner() {
    const data = this.generateSmartBannerObject();
    const s = JSON.stringify(data);
    localStorage.setItem('smartbanner', s);
  }

  private restoreSmartBanner() {
    const smartBannerObject = this.generateSmartBannerObject();

    const s = localStorage.getItem('smartbanner') || JSON.stringify(smartBannerObject);
    const data = JSON.parse(s) || smartBannerObject;

    this.showAgain = new Date(data.showAgain);
  }

  private generateSmartBannerObject(): any {
    return {
      showAgain: this.showAgain,
    };
  }

  private isEnabled(): boolean {
    return this.showAgain <= this.today;
  }

  private isAndroid(): boolean {
    const uaParser = UAParser(navigator.userAgent);

    const correctDevice = uaParser.os.name.toLocaleLowerCase() === 'android';

    const storeIdExists = this.installPrompt.stores.android_id.length > 0;

    return correctDevice && storeIdExists;
  }

  private isIOS(): boolean {
    const correctDevice = !!(navigator.userAgent.match(/(iPad)/)
      || !!(navigator.userAgent.match(/(iPhone)/))
      || (navigator.platform === 'MacIntel' && typeof navigator['standalone'] !== 'undefined'))

    const storeIdExists = this.installPrompt.stores.ios_id.length > 0;

    return correctDevice && storeIdExists;
  }

  private addDays(date: Date, days: number): Date {
    const d = new Date(date);
    d.setDate(d.getDate() + days);

    return d;
  }

  private startOfDay(date: Date): Date {
    const d = new Date(date);
    d.setHours(0, 0, 0, 1);

    return d;
  }

  private endOfDay(date: Date): Date {
    const d = new Date(date);
    d.setHours(23, 59, 59, 999);

    return d;
  }

}
