import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppViewService {
  private renderer: Renderer2;
  private appViewActive: boolean;

  constructor(
    rendererFactory: RendererFactory2,
    private activatedRoute: ActivatedRoute,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.appViewActive = false;

    this.activatedRoute.queryParams
      .subscribe((params: Params) => {
        if (this.appViewActive || params.app_view === 'true') {
          this.appViewActive = true;
          this.renderer.addClass(document.body.parentElement, 'app-view');
        } else {
          this.renderer.removeClass(document.body.parentElement, 'app-view');
        }
      });
  }

}
