<div class="logo">
  <a  class="tagged-link"
      aria-details="Header"
      routerLink="/home">The FA Player</a>
</div>

<div id="menu-container" class="mobile-overlay" [class.opened]="menuOpened">
  <div class="close" (click)="closeMenu()"></div>
  <nav>
    <div><a class="tagged-link" aria-details="Header" routerLink="/home" routerLinkActive="selected">Home</a></div>
    <div><a class="tagged-link" aria-details="Header" routerLink="/live" [class.selected]="isOnLivePage()">Live</a></div>
    <div><a class="tagged-link" aria-details="Header" routerLink="/videos" routerLinkActive="selected">Videos</a></div>
    <div><a class="tagged-link" aria-details="Header" routerLink="/scores" routerLinkActive="selected">Scores</a></div>
    <div><a class="tagged-link" aria-details="Header" routerLink="/team-boxsets" routerLinkActive="selected">Team Boxsets</a></div>
    <div><a class="tagged-link" aria-details="Header" routerLink="/faqs" routerLinkActive="selected">FAQs</a></div>
  </nav>
</div>


<div class="search-container mobile-overlay" [class.opened]="searchOpened">
  <div  class="close tagged-link"
        aria-label="close search"
        aria-details="Header"
        (click)="closeSearch()"></div>
  <form [formGroup]="form" (ngSubmit)="submitSearch($event, headerSearchBar)">
    <div class="search-content">
      <label for="header-search-bar">Search</label>
      <div class="field-container">
        <input #headerSearchBar formControlName="search" id="header-search-bar"
               type="text"
               placeholder="Type keyword, player or team..."/>
        <input type="submit" />
      </div>
    </div>
  </form>
</div>
<div  class="search-icon tagged-link"
      aria-label="open search"
      aria-details="Header"
      (click)="openSearch(headerSearchBar)"></div>
<div  class="menu-mobile-icon tagged-link"
      aria-label="open menu"
      aria-details="Header"
      (click)="openMenu()"></div>
