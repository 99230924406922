<!--<div id="sponsor-container">
  <ng-container *ngIf="footerData && footerData.sponsors">
    <a *ngFor="let sponsor of footerData.sponsors;"
       class="tagged-link"
       aria-details="Sponsor Container"
       target="_blank"
       [href]="sponsor.link">
      <img  [defaultImage]="defaultImage" [lazyLoad]="sponsor.image.url" alt="" />
    </a>
  </ng-container>
</div>-->
<footer>
  <div>
    <div class="logo">
      <a class="tagged-link" aria-details="Footer" href="#">The FA Player</a>
    </div>
    <div class="menu">
      <div class="column">
        <div><a class="tagged-link" aria-details="Footer" routerLink="/home" routerLinkActive="selected">Home</a></div>
        <div><a class="tagged-link" aria-details="Footer" routerLink="/live" routerLinkActive="selected">Live</a></div>
        <div><a class="tagged-link" aria-details="Footer" routerLink="/videos" routerLinkActive="selected">Videos</a></div>
      </div>
      <div class="column">
        <div><a class="tagged-link" aria-details="Footer" routerLink="/scores" routerLinkActive="selected">Scores</a></div>
        <div><a class="tagged-link" aria-details="Footer" routerLink="/team-boxsets" routerLinkActive="selected">Team Boxsets</a></div>
        <div><a class="tagged-link" aria-details="Footer" routerLink="/faqs" routerLinkActive="selected">FAQs</a></div>
      </div>
    </div>
    <div class="social-links">
      <div class="select-container">
        <label>Social links:</label>
        <div class="select">
          <select (change)="onChangeSocialCompetition($event.target.value)">
            <ng-container *ngFor="let item of socialGroup; let idx = index;">
              <option [value]="idx"
                      [textContent]="item.name"></option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="links">
        <ng-container *ngIf="socialGroup.length">
          <div *ngFor="let link of getSelectedSocialGroupLinks()">
            <a class="tagged-link"
               aria-details="Footer"
               target="_blank"
               [className]="link.class"
               [href]="link.link"
               [textContent]="link.class"></a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div>
    <div class="links-container">
      <ng-container *ngIf="footerData && footerData.footer_links">
        <div *ngFor="let footerLink of footerData.footer_links;">
          <ng-container *ngIf="footerLink.label === 'Cookie Settings'; then cookieSettings; else everythingElse"></ng-container>

          <ng-template #cookieSettings>
            <a  class="ot-sdk-show-settings tagged-link"
                aria-details="Footer">Cookie Settings</a>
          </ng-template>

          <ng-template #everythingElse>
            <a  class="tagged-link"
                aria-details="Footer"
                target="_blank"
                [href]="footerLink.external_link"
                [textContent]="footerLink.label"></a>
          </ng-template>

        </div>
      </ng-container>
    </div>

    <div class="copyright">
      <ng-container *ngIf="footerData && footerData.copyright">
        {{ footerData.copyright }}
      </ng-container>
    </div>
  </div>

</footer>
