import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  HostBinding,
  Inject, OnDestroy,
  OnInit,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Config } from '../../../../app.config';

@Component({
  selector: 'app-primary-header',
  templateUrl: './primary-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryHeaderComponent implements OnInit, OnDestroy {
  @HostBinding('id') idAttribute;

  form: FormGroup = new FormGroup({
    search: new FormControl(),
  });

  public menuOpened = false;
  public searchOpened = false;
  private routerSubscription: Subscription;

  constructor(private router: Router,
              @Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2,
              private cd: ChangeDetectorRef) {
    this.idAttribute = 'primary-header';
  }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.closeMenu();
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

  public isOnLivePage(): boolean {
    const path = window.location.pathname;

    return path.startsWith(Config.eventPreview.path, 1)
        || path.startsWith(Config.fixturePreview.path, 1)
        || path.startsWith(Config.live.path, 1);
  }

  openMenu() {
    this.menuOpened = true;
    window.scrollTo(0, 0);
    setTimeout(() => {
      this.renderer.addClass(this.document.body, 'mobile-overlay-opened');
    }, 700);
  }

  closeMenu() {
    this.menuOpened = false;
    this.renderer.removeClass(this.document.body, 'mobile-overlay-opened');
    // trigger refresh html...
    if (this.cd) {
      this.cd.markForCheck();
    }

  }


  openSearch(inputElement: HTMLInputElement) {
    this.searchOpened = true;
    window.scrollTo(0, 0);
    // waiting the end of CSS animation
    setTimeout(() => {
      this.renderer.addClass(this.document.body, 'mobile-overlay-opened');
      inputElement.focus();
    }, 700);
  }

  closeSearch() {
    this.searchOpened = false;
    this.renderer.removeClass(this.document.body, 'mobile-overlay-opened');
  }

  submitSearch(event: Event, inputElement: HTMLInputElement) {
    event.preventDefault();
    const search = this.form.get('search').value;
    this.closeSearch();
    if (search !== '') {
      this.form.setValue({
        search: '',
      });

      inputElement.blur();

      this.router.navigate(['/videos/all'], { queryParams: { search, preserve: true } });
    }
  }
}
