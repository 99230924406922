<div class="ck-msg"
     [innerHTML]="getMessage() | safeHtml">
</div>

<div class="ck-btn">
  <button class="tagged-link"
          aria-label="Set Cookie Consent"
          aria-details="Cookie Banner"
          [textContent]="getButtonText()"
          (click)="setCookieConsent()">
  </button>
</div>
