import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Config } from './app.config';

const routes: Routes = [{
  path: Config.home.path,
  loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
}, {
  path: Config.live.path,
  loadChildren: () => import('./pages/fixtures/fixtures.module').then(m => m.FixturesModule),
}, {
  path: Config.eventPreview.path,
  loadChildren: () => import('./pages/event-preview/event-preview.module').then(m => m.EventPreviewModule),
}, {
  path: Config.fixturePreview.path,
  loadChildren: () => import('./pages/event-preview/event-preview.module').then(m => m.EventPreviewModule),
}, {
  path: Config.archive.path,
  loadChildren: () => import('./pages/archive/archive.module').then(m => m.ArchiveModule),
}, {
  path: Config.scores.path,
  loadChildren: () => import('./pages/scores/scores.module').then(m => m.ScoresModule),
}, {
  path: Config.teamHighLights.path,
  loadChildren: () => import('./pages/team-highlights/team-highlights.module').then(m => m.TeamHighlightsModule),
}, {
  path: Config.support.path,
  loadChildren: () => import('./pages/support/support.module').then(m => m.SupportModule),
}, {
  path: Config.browse.path,
  loadChildren: () => import('./pages/browse/browse.module').then(m => m.BrowseModule),
}, {
  path: Config.boxsets.path,
  loadChildren: () => import('./pages/boxsets/boxsets.module').then(m => m.BoxsetsModule),
}, {
  path: Config.video.path,
  loadChildren: () => import('./pages/video/video.module').then(m => m.VideoModule),
}, {
  path: Config.b2c.path,
  loadChildren: () => import('./pages/b2c/b2c.module').then(m => m.B2CModule),
}, {
  path: '**',
  redirectTo: Config.home.path,
  pathMatch: 'full',
}];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
