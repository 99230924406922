<ng-template #modalRef let-modal>
  <div class="modal-header">
    <h5 class="modal-title">CHOOSE YOUR<br />FAVOURITE TEAMS</h5>
    <h6 class="modal-subtitle">To change your preferences, head to<br />‘My Teams’ in The FA Player app settings.</h6>
    <button type="button" class="close tagged-link" aria-details="Fav Teams Modal" data-dismiss="modal" aria-label="Close Fav Teams Modal" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div id="fa-fav-teams-modal-body" class="modal-body">
    <div  class="team-tiles"
          [class.form-error]="faFavTeamFormStatus === FaFavTeamModalFormStatusEnum.tooManyTeams">
      <div class="team-tile-container"
        *ngFor="let anywhereTeam of localAnywhereTeam; index as i"
      >
        <div class="team-tile will-transition tagged-link"
            aria-details="Fav Teams Modal"
            [class.selected]="isTeamSelected(anywhereTeam)"
            [class.selected-error]="faFavTeamFormStatus === FaFavTeamModalFormStatusEnum.tooManyTeams && faFavTeamLastClick === anywhereTeam"
            (click)="toggleTeam(anywhereTeam)"
        >
          <img [src]="anywhereTeam.logo"/>
          <span [innerText]="anywhereTeam.label"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button"
      class="btn-go will-transition tagged-link"
      aria-label="Save Fav Teams"
      aria-details="Fav Teams Modal"
      [class.btn-cta]="maxTeamsSelected()"
      [disabled]="!isGoButtonEnabled()"
      (click)="saveChoices()">Let's go!</button>

    <div [ngSwitch]="faFavTeamFormStatus">
      <!-- the same view can be shown in more than one case -->
      <div *ngSwitchCase="FaFavTeamModalFormStatusEnum.moreTeamsNeeded">
        <div class="error">
          Sorry! You have to select at least one team.
        </div>
      </div>
      <div *ngSwitchCase="FaFavTeamModalFormStatusEnum.tooManyTeams">
        <div class="error">
          Sorry! You can only select up to two teams.
        </div>
      </div>
      <div *ngSwitchCase="FaFavTeamModalFormStatusEnum.submitting">
        <div class="info">
          We're setting your favourite teams. Hold on!
        </div>
      </div>
      <div *ngSwitchCase="FaFavTeamModalFormStatusEnum.networkError">
        <div class="info">
          Oh no! We couldn't save your favourite teams. Please try again in a moment.
        </div>
      </div>
      <!--default case when there are no matches -->
      <div *ngSwitchDefault>
        <div class="info">
          You can select up to two favourite teams.
        </div>
      </div>
    </div>
  </div>
</ng-template>
