<div class="links">
  <div><a class="icon tickets tagged-link" aria-details="Header" target="_blank" href="https://ticketing.thefa.com/">Tickets</a></div>
  <div><a class="icon shop tagged-link" aria-details="Header" target="_blank" href="https://www.englandstore.com/stores/fa/en">Shop</a></div>
  <div><a class="tagged-link" aria-details="Header" target="_blank" href="http://www.wembleystadium.com/">Wembley</a></div>
  <div><a class="tagged-link" aria-details="Header" target="_blank" href="http://www.thefa.com/about-football-association/st-georges-park">St. George's Park</a></div>
</div>
<div class="myaccount-container">
  <a *ngIf="!(isUserSignedInObservable() | async)"
     class="user-icon tagged-link"
     aria-details="Header"
     (click)="clickLogin()">SIGN IN / SIGN UP</a>

  <ng-container *ngIf="(isUserSignedInObservable() | async)">
    <a *ngIf="false"
       class="team-icon tagged-link"
       aria-details="Header"
       [routerLink]=""
       (click)="clickOpenFavoriteTeamModal()">
       <span>Favourite Teams</span>
       <span  *ngIf="(favTeamDataObservable() | async)?.length !== 0"
              class="team-badge"
              [innerText]="(favTeamDataObservable() | async)?.length"></span>
    </a>
    <a class="user-icon user-sign-out-icon tagged-link"
      aria-details="Header"
      [routerLink]=""
      (click)="clickLogout()">Sign Out</a>
  </ng-container>
</div>
