import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import {WpCookieMessage} from '../../types/wordpress/components/wp-common-fields.type';

@Component({
  selector: 'app-cookie-notification',
  templateUrl: './cookie-notification.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CookieNotificationComponent {
  private static readonly cookieNotificationPointer = 'tv.thefa';

  @HostBinding('attr.isConsentGiven') isUserConsentGiven;
  @Input() cookieMessage: WpCookieMessage;

  private cookieMessageFallBack: WpCookieMessage;

  private static getUserConsent(): boolean {
    try {
      const userConsent = localStorage.getItem(CookieNotificationComponent.cookieNotificationPointer);
      return !!userConsent;
    } catch (e) {
      return false;
    }
  }

  private static setUserConsent(userFlag: boolean): void {
    try {
      localStorage.setItem(CookieNotificationComponent.cookieNotificationPointer, JSON.stringify(userFlag));
    } catch (e) {
    }
  }

  constructor() {
    this.isUserConsentGiven = CookieNotificationComponent.getUserConsent();
    this.cookieMessageFallBack = {
      cookie_message: 'This website uses cookies to ensure you get the best experience on our website.' +
        'Learn more by visiting our Cookie Policy page.',
      button_text: 'OK, GOT IT!'
    };
  }

  setCookieConsent(): void {
    CookieNotificationComponent.setUserConsent(true);
    this.isUserConsentGiven = true;
  }

  getMessage(): string {
    return this.cookieMessage && this.cookieMessage.cookie_message
      ? this.cookieMessage.cookie_message
      : this.cookieMessageFallBack.cookie_message;
  }

  getButtonText(): string {
    return this.cookieMessage && this.cookieMessage.button_text
      ? this.cookieMessage.button_text
      : this.cookieMessageFallBack.button_text;
  }
}
