<div *ngIf="showAndroid" class="smartbanner smartbanner-android">
  <div class="smartbanner-container">
    <a href="javascript:void(0);" class="smartbanner-close" (click)="dismissBanner()">×</a>
    <span class="smartbanner-icon" [style.backgroundImage]="'url(' + icon + ')'"></span>
    <div class="smartbanner-info">
      <div class="smartbanner-title" [innerText]="installPrompt.title"></div>
      <div [innerText]="installPrompt.display_name"></div>
      <span>{{ installPrompt.stores.android_price }} - {{ installPrompt.stores.android_text }}</span>
    </div>
    <a [href]="linkAndroid" class="smartbanner-button" (click)="dismissCTA()">
      <span class="smartbanner-button-text" [innerText]="installPrompt.cta_text"></span>
    </a>
  </div>
</div>

<div *ngIf="showIOS" class="smartbanner smartbanner-ios">
  <div class="smartbanner-container">
    <a href="javascript:void(0);" class="smartbanner-close" (click)="dismissBanner()">×</a>
    <span class="smartbanner-icon" [style.backgroundImage]="'url(' + icon + ')'"></span>
    <div class="smartbanner-info">
      <div class="smartbanner-title" [innerText]="installPrompt.title"></div>
      <div [innerText]="installPrompt.display_name"></div>
      <span>{{ installPrompt.stores.ios_price }} - {{ installPrompt.stores.ios_text }}</span>
    </div>
    <a [href]="linkIOS" class="smartbanner-button" (click)="dismissCTA()">
      <span class="smartbanner-button-text" [innerText]="installPrompt.cta_text"></span>
    </a>
  </div>
</div>
