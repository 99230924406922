import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { B2cService } from '../../../../core/services/b2c/b2c.service';
import { FaFavTeamsService } from '../../../../core/services/fa-favteams.service';
import { ModalService } from '../../../../core/services/modal.service';
import { WINDOW } from '../../../providers/window-provider';
import { FavTeam } from '../../../types/FavTeamsApiResponse.type';

@Component({
  selector: 'app-fa-secondary-header',
  templateUrl: './fa-secondary-header.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaSecondaryHeaderComponent implements OnInit, OnDestroy {
  @HostBinding('id') idAttribute;

  private signOutSubscription: Subscription;

  constructor(@Inject(WINDOW) private window: Window,
              private b2cService: B2cService,
              private faFavTeams: FaFavTeamsService,
              private modalService: ModalService,
  ) {
    this.idAttribute = 'secondary-header';
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    if (this.signOutSubscription) {
      this.signOutSubscription.unsubscribe();
    }
  }

  favTeamDataObservable(): Observable<FavTeam[]> {
    return this.faFavTeams.getFavTeamsObservable();
  }

  isUserSignedInObservable(): Observable<any> {
    return this.b2cService.getUserObservable();
  }

  clickOpenFavoriteTeamModal() {
    this.modalService.openModal('fa-fav-teams');
  }

  clickLogin(): void {
    this.b2cService.logIn();
  }

  clickSignUp(): void {
    this.b2cService.signUp();
  }

  clickForgotEmail(): void {
    this.b2cService.forgotEmail();
  }

  clickForgotFcode(): void {
    this.b2cService.forgotFcode();
  }

  clickForgotPassword(): void {
    this.b2cService.forgotPassword();
  }

  clickLogout(): void {
    this.b2cService.logOut();
  }
}
