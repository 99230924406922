export class Config {
  public static home = {
    path: 'home',
    module: './pages/home/home.module',
    moduleName: 'Home',
  };
  public static live = {
    path: 'live',
    module: './pages/fixtures/fixtures.module',
    moduleName: 'Fixtures',
  };
  public static eventPreview = {
    path: 'event-preview',
    module: './pages/event-preview/event-preview.module',
    moduleName: 'EventPreview',
  };
  public static fixturePreview = {
    path: 'fixture-preview',
    module: './pages/event-preview/event-preview.module',
    moduleName: 'EventPreview',
  };
  public static archive = {
    path: 'videos',
    module: './pages/archive/archive.module',
    moduleName: 'Archive',
  };
  public static scores = {
    path: 'scores',
    module: './pages/scores/scores.module',
    moduleName: 'Scores',
  };
  public static teamHighLights = {
    path: 'team-boxsets',
    module: './pages/team-highlights/team-highlights.module',
    moduleName: 'TeamHighLights',
  };
  public static support = {
    path: 'faqs',
    module: './pages/support/support.module',
    moduleName: 'Support',
  };
  public static browse = {
    path: 'browse',
    module: './pages/browse/browse.module',
    moduleName: 'Browse',
  };
  public static boxsets = {
    path: 'boxsets',
    module: './pages/boxsets/boxsets.module',
    moduleName: 'Boxsets',
  };
  public static video = {
    path: 'video',
    module: './pages/video/video.module',
    moduleName: 'Video',
  };
  public static b2c = {
    path: 'b2c',
    module: './pages/b2c/b2c.module',
    moduleName: 'B2C',
  };

  public static list = [
    Config.archive,
    Config.boxsets,
    Config.browse,
    Config.eventPreview,
    Config.fixturePreview,
    Config.home,
    Config.live,
    Config.scores,
    Config.support,
    Config.teamHighLights,
    Config.video,
    Config.b2c,
  ];
}
