<ng-container *ngIf="appReady">
  <app-smart-banner *ngIf="installPrompt"
                    [installPrompt]="installPrompt"></app-smart-banner>

  <app-header></app-header>

  <div id="page-container">
    <router-outlet></router-outlet>
  </div>
  <app-footer [footerData]="wpFooterData"></app-footer>
  <app-modals></app-modals>
  <app-cookie-notification [cookieMessage]="wpCookieMessage"></app-cookie-notification>
</ng-container>
