import { Injectable, isDevMode } from '@angular/core';

import { BroadcastService, MsalService } from '@azure/msal-angular';

import { AuthenticationParameters, AuthResponse, ClientAuthError, CryptoUtils, Logger } from 'msal';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { B2CAccount } from './b2c.type';

import { B2CStatus } from './b2c.enum';

import * as b2c from './b2c.config';
import { environment } from 'src/environments/environment';
import { DeviceUtils } from 'amg-fe-utils';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { B2CKsession } from './b2c.ksession.type';
import { SubSink } from 'subsink';

@Injectable({
  providedIn: 'root',
})
export class B2cService {
  private lastAuthenticationParameters: AuthenticationParameters;

  private ssoStatusBS: BehaviorSubject<B2CStatus>;

  private userBS: BehaviorSubject<B2CAccount>;
  private fanCodeBS: BehaviorSubject<string>;
  private accessTokenBS: BehaviorSubject<string>;

  private subSink: SubSink;

  private tokenRetrievalByPopup: boolean;
  private tokenRetrievalByRedirect: boolean;

  /**
   * - (-1) = User is logged in, has entitlements and KS has been generated
   * - (0)  = User is not Logged in
   * - (1)  = User is logged in but does not have appropriate membership
   * - (5)  = Error
   *
   * @param status - one of these numbers: -1, 0, 1, 5.
   */
  private static isRequestStatusValid(status: number): boolean {
    return status === -1
      || status === 0
      || status === 1
      || status === 5;
  }

  constructor(
    private httpClient: HttpClient,
    private broadcastService: BroadcastService,
    private authService: MsalService,
  ) {

    this.subSink = new SubSink();

    this.tokenRetrievalByPopup = false;
    this.tokenRetrievalByRedirect = false;

    this.ssoStatusBS = new BehaviorSubject(B2CStatus.INIT);

    this.userBS = new BehaviorSubject(null);
    this.fanCodeBS = new BehaviorSubject(null);
    this.accessTokenBS = new BehaviorSubject(null);

    const urlQueryParams = new URLSearchParams(window.location.search);

    if (urlQueryParams.get('doLogin')) {
      history.replaceState(null, null, `${window.location.origin}`);
      this.logIn();

      return;
    }

    this.userBS.subscribe((u) => {
      console.info('updated user ' + u);
    });
    this.accessTokenBS.subscribe((a) => {
      console.info('updated token ' + a);
    });

    this.broadcastService.getMSALItem().subscribe((a) => {
      console.info(a);
    });
    this.broadcastService.getMSALSubject().subscribe((a) => {
      console.info(a);
    });


    this.getUserObservable().subscribe((account: B2CAccount) => this.triggerTokenRetrieval());

    // event listeners for authentication status
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/msal-angular-v1/lib/msal-angular
    this.broadcastService.subscribe('msal:loginSuccess', success => this.loginSuccess(success));
    this.broadcastService.subscribe('msal:loginFailure', error => this.loginFailure(error));

    this.broadcastService.subscribe('msal:acquireTokenSuccess', success => this.acquireTokenSuccess(success));
    this.broadcastService.subscribe('msal:acquireTokenFailure', error => this.acquireTokenFailure(error));

    this.broadcastService.subscribe('msal:ssoSuccess', success => this.ssoSuccess(success));
    this.broadcastService.subscribe('msal:ssoFailure', error => this.ssoFailure(error));

    // redirect callback for redirect flow (IE)
    this.authService.handleRedirectCallback((authError, response) => this.handleRedirectCallback);

    // this.authService.setLogger(this.newLogger());


    this.init();
  }

  private init() {
    const user: B2CAccount = this.getUser();

    if (user) {
      // this.logOut();
      this.updateUserObservable();
      this.triggerTokenRetrieval();
    }
  }

  public cancelRequest() {
    this.updateObservable(this.ssoStatusBS, B2CStatus.CANCELLED);
  }

  public logIn(forceRedirect?: boolean) {
    this.triggerAuthService(b2c.b2cPolicies.authorities.signInPolicy, forceRedirect);
  }

  public logOut() {
    this.logoutSilent();

    this.authService.logout();
  }

  private logoutSilent() {
    this.updateObservable(this.accessTokenBS, null);
    this.updateObservable(this.fanCodeBS, null);
    this.updateObservable(this.userBS, null);

    this.subSink.unsubscribe();
  }

  public signUp(forceRedirect?: boolean) {
    this.triggerAuthService(b2c.b2cPolicies.authorities.signUpPolicy, forceRedirect);
  }

  public forgotEmail(forceRedirect?: boolean) {
    this.triggerAuthService(b2c.b2cPolicies.authorities.forgottenEmailPolicy, forceRedirect);
  }

  public forgotFcode(forceRedirect?: boolean) {
    this.triggerAuthService(b2c.b2cPolicies.authorities.dontKnowMyFanPolicy, forceRedirect);
  }

  public forgotPassword(forceRedirect?: boolean) {
    this.triggerAuthService(b2c.b2cPolicies.authorities.passwordResetPolicy, forceRedirect);
  }

  public getUser(): B2CAccount {
    const user: B2CAccount = (this.authService.getAccount() as unknown as B2CAccount);


    if (user) {
      const exp = Number.parseInt(user.idToken.exp, 10) * 1000;

      if (exp > Date.now()) {
        return user;
      }
    }

    this.logoutSilent();
    return null;
  }

  public getFanCode(): string {
    const user = this.getUser();

    if (user) {
      const fanCode = user.idToken.extension_FANCode || user.idToken._userProfile || null;

      return fanCode;
    }

    return null;
  }

  public getUserObservable(): Observable<B2CAccount> {
    return this.userBS.asObservable();
  }

  private updateUserObservable() {
    const account: B2CAccount = this.getUser();
    const fanCode: string = this.getFanCode();

    this.updateObservable(this.userBS, account);
    this.updateObservable(this.fanCodeBS, fanCode);
  }

  public getFanCodeObservable(): Observable<string> {
    return this.fanCodeBS.asObservable();
  }

  private updateObservable(bs: BehaviorSubject<any>, o: any) {
    if (bs.value !== o) {
      bs.next(o);
    }
  }

  public isUserLoggedIn(): boolean {
    const account: B2CAccount = this.getUser();
    const isSignedIn: boolean = !!account;

    return isSignedIn;
  }

  public handleUrlFragment(fragment: string): boolean {
    switch (fragment) {
      case b2c.urlFragments.signInFragment: {
        this.logIn(true);
        break;
      }
      case b2c.urlFragments.signUpFragment: {
        this.signUp(true);
        break;
      }
      case b2c.urlFragments.passwordResetFragment: {
        this.forgotPassword(true);
        break;
      }
      case b2c.urlFragments.forgottenEmailFragment: {
        this.forgotEmail(true);
        break;
      }
      case b2c.urlFragments.dontKnowMyFanFragment: {
        this.forgotFcode(true);
        break;
      }
      default: {
        return true;
      }
    }

    return false;
  }

  public requestKalturaSessionAndFanCode(entryId: string): Observable<B2CKsession> {
    const accessToken = this.accessTokenBS.value || '';

    if (accessToken.length === 0) {
      const requestBS: BehaviorSubject<B2CKsession> = new BehaviorSubject(null);

      this.subSink.unsubscribe();

      this.subSink.sink = this.accessTokenBS
        .subscribe((token: string) => {
          if (token && token.length > 0) {
            this.subSink.sink = this.requestKalturaSessionAndFanCodeWithToken(entryId, token)
              .subscribe((a: any) => {
                if (a) {
                  requestBS.next(a);
                }
              });
          }
        });

      this.triggerTokenRetrieval();
      return requestBS.asObservable();
    }

    return this.requestKalturaSessionAndFanCodeWithToken(entryId, accessToken);
  }

  public isIFrame(): boolean {
    return b2c.isIframe;
  }

  public ssoStatusObserver(): Observable<B2CStatus> {
    return this.ssoStatusBS.asObservable();
  }

  private handleRedirectCallback(authError, response) {
    if (authError) {
      console.error('Redirect Error: ', authError.errorMessage);
      return;
    }

    console.log('Redirect Success: ', response);
  }

  private loginSuccess(success) {
    // We need to reject id tokens that were not issued with the default sign-in policy.
    // 'acr' claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use 'tfp' instead of 'acr')
    // To learn more about b2c tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
    if (success.idToken.claims.acr.toLocaleLowerCase() === b2c.b2cPolicies.names.passwordResetPolicy.toLocaleLowerCase()) {
      //window.alert('Password has been reset successfully. \nPlease sign-in with your new password');
      this.logOut();
      this.logIn();
    } else {
      console.log('login succeeded. id token acquired at: ' + new Date().toString());
      console.log(success);

      this.updateUserObservable();

      this.updateObservable(this.ssoStatusBS, B2CStatus.DONE);
    }
  }

  private loginFailure(error) {
    if (error.errorMessage) {
      // Check for forgot password error
      // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
      if (this.hasUserForgottenPassword(error)) {
        this.triggerAuthService(b2c.b2cPolicies.authorities.passwordResetPolicy);
      } else if (this.hasUserCancelledSignup(error)) {
        // If user has cancelled then return them to the previous page
        this.handleErrorNotification(error);
      } else if (this.hasUserReturnedFromB2C(error)) {
        this.retryTriggerAuthService();
      } else {
        this.handleErrorNotification(error);
      }
    }

    this.updateObservable(this.ssoStatusBS, B2CStatus.DONE);
  }

  private acquireTokenSuccess(authResponse: AuthResponse) {
    const accessToken: string = authResponse.idToken.rawIdToken;
    this.updateObservable(this.accessTokenBS, accessToken);
  }

  private acquireTokenFailure(error) {
    switch (error.name) {
      case 'InteractionRequiredAuthError': {
        this.triggerTokenRetrievalPopup();
        break;
      }
      case 'ClientAuthError': {
        this.triggerTokenRetrievalRedirect();
        break;
      }
    }
  }

  private ssoSuccess(success) {
    debugger;
  }

  private ssoFailure(error) {
    debugger;
  }

  private retryTriggerAuthService() {
    if (this.lastAuthenticationParameters) {
      this.triggerAuthService(this.lastAuthenticationParameters, true);
    }
  }

  private saveRoute(): void {
    const href: string = window.location.href;
    const b: string = btoa(href);
    const reversedB: string = b.split('').reverse().join('');

    localStorage.setItem('loginRoute', reversedB);
  }

  private requestKalturaSessionAndFanCodeWithToken(entryId: string, accessToken: string): Observable<B2CKsession> {
    const headers = {
      jwt: accessToken,
    };

    return this.httpClient
      .get<{ FanCode: number; KSession: string; Status: number; Message: string; Timestamp: string; }>(environment.backendKsApi, {
        headers,
        params: {
          entryId,
          ...(DeviceUtils.userAgent.IE
            ? {
              a: Date.now().toString(), // IE fix to invalidate the cache.
            } : {}),
        },
      }).pipe(
        map((value) => {
          if (value && B2cService.isRequestStatusValid(value.Status)) {
            return {
              ks: value.KSession,
              fanCode: value.FanCode || '',
              isLoggedIn: (value.Status === -1 || value.Status === 1),
              isUserEntitled: value.Status === -1,
            };
          }


          return of(null);
        }),
        catchError((err, caught) => {
          if (isDevMode()) {
            console.log(err, caught);
          }


          return of(null);
        }),
      );
  }

  private saveOrRestoreRoute(authenticationParameters: AuthenticationParameters): boolean {
    if (this.ssoStatusBS.value === B2CStatus.CANCELLED) {
      this.restoreRoute();
      return false;
    } else if (authenticationParameters === b2c.b2cPolicies.authorities.signInPolicy) {
      this.saveRoute();
      return true;
    }
    return true;
  }

  private restoreRoute(): void {
    const reversedB: string = localStorage.getItem('loginRoute');
    const b: string = reversedB.split('').reverse().join('');
    const href: string = atob(b);

    window.location.href = href;

    localStorage.removeItem('loginRoute');
  }

  private triggerTokenRetrieval() {
    this.triggerTokenRetrievalSilent();
  }

  private triggerTokenRetrievalSilent() {
    const user: B2CAccount = this.getUser();

    if (user) {
      const request: AuthenticationParameters = this.newAuthenticationParameters();

      try {
        this.authService.acquireTokenSilent(request);
      } catch (error) {
        this.acquireTokenFailure({
          name: 'ClientAuthError',
        });
        console.error('acquireTokenSilent failure');
      }
    }
  }

  private triggerTokenRetrievalPopup() {
    if (!this.tokenRetrievalByPopup) {
      const user: B2CAccount = this.getUser();

      if (user) {
        const request: AuthenticationParameters = this.newAuthenticationParameters();

        this.tokenRetrievalByPopup = true;
        try {
          this.authService.acquireTokenPopup(request);
        } catch (error) {
          this.acquireTokenFailure({
            name: 'ClientAuthError',
          });
          console.error('acquireTokenPopup failure');
        }
      }
    }
  }

  private triggerTokenRetrievalRedirect() {
    const user: B2CAccount = this.getUser();

    if (user) {
      const request: AuthenticationParameters = this.newAuthenticationParameters();

      this.authService.acquireTokenRedirect(request);
    }
  }

  private triggerAuthService(authenticationParameters: AuthenticationParameters, forceRedirect?: boolean) {
    if (this.ssoStatusBS.value !== B2CStatus.CONNECTING) {
      const allowContinue: boolean = this.saveOrRestoreRoute(authenticationParameters);

      if (allowContinue) {
        forceRedirect = (typeof (forceRedirect) === 'undefined')
          ? true
          : forceRedirect;

        this.updateObservable(this.ssoStatusBS, B2CStatus.CONNECTING);

        this.lastAuthenticationParameters = authenticationParameters;

        if (b2c.isIE || forceRedirect) {
          this.authService.loginRedirect(authenticationParameters);
        } else {
          this.authService.loginPopup(authenticationParameters);
        }
      } else {
        this.updateObservable(this.ssoStatusBS, B2CStatus.DONE);
      }
    }
  }

  private handleErrorNotification(error: ClientAuthError) {
    switch (error.errorCode) {
      case 'endpoints_resolution_error': {
        // could not connect to b2c
        this.updateObservable(this.ssoStatusBS, B2CStatus.ERROR_CONNECTING);
        break;
      }
      case 'user_cancelled': {
        // ignore user cancellation
        this.updateObservable(this.ssoStatusBS, B2CStatus.CANCELLED);
        break;
      }
      case 'popup_window_error': {
        this.retryTriggerAuthService();
        break;
      }
      default: {
        this.updateObservable(this.ssoStatusBS, B2CStatus.DONE);
        console.log('login failed');
        console.log(error.errorCode);
        console.log(error);
      }
    }
  }

  private newAuthenticationParameters(): AuthenticationParameters {
    const account = this.authService.getAccount();

    return {
      account: account,
      scopes: b2c.apiConfig.b2cScopes,
    };
  }

  private newLogger(): Logger {
    return new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: false,
    });
  }

  private hasUserForgottenPassword(error): boolean {

    return error.errorMessage.indexOf('AADB2C90118') > -1;
  }

  private hasUserCancelledSignup(error): boolean {
    return error.errorMessage.indexOf('AADB2C90091') > -1;
  }

  private hasUserReturnedFromB2C(error): boolean {
    return error.errorCode.indexOf('login_progress_error') > -1
      && this.lastAuthenticationParameters !== b2c.b2cPolicies.authorities.signInPolicy;
  }

}
