import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private openModalBehaviourSubject: BehaviorSubject<string>;
  private openModalObservable: Observable<string>;

  private closeModalBehaviourSubject: BehaviorSubject<string>;
  private closeModalObservable: Observable<string>;

  constructor() {
    this.openModalBehaviourSubject = new BehaviorSubject('');
    this.openModalObservable = this.openModalBehaviourSubject.asObservable();

    this.closeModalBehaviourSubject = new BehaviorSubject('');
    this.closeModalObservable = this.closeModalBehaviourSubject.asObservable();
  }

  openModal(id: string) {
    this.openModalBehaviourSubject.next(id);
  }

  closeModal(id: string) {
    this.closeModalBehaviourSubject.next(id);
  }

  getOpenModalObservable(): Observable<string> {
    return this.openModalObservable;
  }

  getCloseModalObservable(): Observable<string> {
    return this.closeModalObservable;
  }

}
