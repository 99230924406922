import { Injectable } from '@angular/core';
import { UAParser } from 'ua-parser-js';
import { environment } from '../../../environments/environment';
import { Config } from '../../app.config';
import { Archive } from '../../pages/archive/archive.static';

declare var dataLayer;

@Injectable({
  providedIn: 'root'
})
export class GtagService {
  // push only 25%, 50%, 75%, and 100% video playback events
  private static percentageMarkersOriginalValues: number[] = [0.25, 0.50, 0.75, 1.00];

  private percentageMarkers: number[];

  private cacheUserType: {
    userLoginType: 'GUEST' | 'EMAIL',
    userLoginId: string,
  };

  private uaParser = new UAParser();

  constructor() { }

  /*
   * @userType: GUEST / EMAIL
   * @userID: FAN ID
   */
  public setUserType(
    userLoginType: 'GUEST' | 'EMAIL',
    userLoginId: string,
  ) {
    this.cacheUserType = {
      userLoginType: userLoginType,
      userLoginId: userLoginId,
    };
  }

  pageNavigationEventTimeout: any;
  lastPageNavigationUrl: string;

  /*
   * 4.1. Page Specific Static Values inside DataLayer
   * @pageName: name of page,
   * @pageUrl: url of page,
   * @pageType: type of page module,
   */
  public pageNavigationEvent(
    pageName: string,
    pageUrl: string,
    pageType: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
    userLoginId: string = this.cacheUserType.userLoginId,
  ) {
    if (pageUrl.indexOf(Config.archive.path + '/') > -1) {
      pageUrl = pageUrl.split('&' + Archive.propertySearch)[0];
    }

    if (this.lastPageNavigationUrl !== pageUrl) {

      if (this.pageNavigationEventTimeout) {
        clearTimeout(this.pageNavigationEventTimeout);
      }

      this.pageNavigationEventTimeout = setTimeout(() => {
        const o = {
          page_name: pageName,
          page_url: pageUrl,
          page_type: pageType,
          user_login_type: userLoginType,
          user_login_ID: userLoginId,
        };

        this.lastPageNavigationUrl = pageUrl;

        this.doFinalPush(o);
      }, 1000);
    }
  }

  /*
   * 5.1. User Sign In or Sign Up
   */
  public userSignUpEvent(
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
    userLoginId: string = this.cacheUserType.userLoginId,
  ) {
    const o = {
      event: 'user_signup',
      user_login_ID: userLoginId,
    };

    this.doFinalPush(o);
  }

  public userLogInEvent(
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
    userLoginId: string = this.cacheUserType.userLoginId,
  ) {
    const o = {
      event: 'user_login',
      user_login_type: userLoginType,
      user_login_ID: userLoginId,
    };

    this.doFinalPush(o);
  }

  /*
   * 6.1. Custom Event Tracking
   * Capture sharing of content, with some custom parameters to track relevant variables to action of sharing.
   * @pageName: name of page
   * @modeOfShare: social network name
   * @videoTitle: The name of the video user interacted
   * @videoCategory: Video Category: (Ex: Highlights, Editor Picks, etc.)
   * @videoPlaylist: video playlist
   */
  public userShareVideoEvent(
    pageName: string,
    modeOfShare: string,
    videoTitle: string,
    videoCategory: string[],
    videoPlaylist: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'social_share',
      mode_of_share: modeOfShare,
      video_title: videoTitle,
      video_category: videoCategory.join(', '),
      video_playlist: videoPlaylist,
      page_name: pageName,
      user_login_type: userLoginType,
    };

    this.doFinalPush(o);
  }

  /*
   * 7.1. Navigation Button
   * Capture micro user interactions as they interact with CTAs across the site.
   * @pageName: name of page
   * @navigationLocation: location of button on page
   * @buttonName: Text or description of button
   * @target: internal / external
   */
  public userInteractsLinkEvent(
    pageName: string,
    navigationLocation: string,
    buttonName: string,
    target: 'INTERNAL' | 'EXTERNAL',
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'button_interaction',
      button_name: buttonName,
      navigation_location: navigationLocation,
      navigation_type: target,
      page_name: pageName,
      user_login_type: userLoginType,
    };

    this.doFinalPush(o);
  }

  /*
   * 10.1. Scores section
   * @pageName: name of page
   * @latestFilters: latest filter chosen
   * @team: team chosen
   * @season: season chosen
   */
  public optaResultsFetchedEvent(
    pageName: string,
    latestFilters: { [s: string]: string },
    team: string,
    season: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const currentFilter: string = this.getCurrentFilter(latestFilters);
    this.backupOptaResultsFilters(latestFilters);

    const o = {
      event: 'scores_open',
      filter_chosen: currentFilter,
      filter_by_teams: team,
      filter_by_seasons: season,
      page_name: pageName,
      user_login_type: userLoginType,
    };

    this.doFinalPush(o);
  }

  /*
   * 8.3. Scores section
   * Capture keyword details and results applied in search filter section
   * @pageName: name of page
   * @searchKeyword: the keyword searched
   * @numberOfResults: number of results returned
   */
  public videoResultsFetchedEvent(
    pageName: string,
    searchKeyword: string,
    numberOfResults: number,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'video_search_results',
      search_keyword: searchKeyword,
      search_result_count: numberOfResults,
      page_name: pageName,
      user_login_type: userLoginType,
    };

    this.doFinalPush(o);
  }

  /*
   * 9. Live section
   * Capture the performance of Live Match Video
   * @pageName: name of page
   * @videoTitle: title of video
   * @buttonName: Text or description of button
   * @buttonStatus: 'OPEN' | 'CLOSED'
   */
  public videoLiveOpen(
    pageName: string,
    videoTitle: string,
    buttonName: string,
    buttonStatus: 'OPEN' | 'CLOSED',
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'live_section',
      live_status: buttonStatus,
      button_name: buttonName,
      video_title: videoTitle,
      cast_screen: 'website',
      device_name: this.getDeviceType(),
      page_name: pageName,
      user_login_type: userLoginType,
    };

    this.doFinalPush(o);
  }

  public videoLiveHeroBannerOpen(
    pageName: string,
    videoTitle: string,
    buttonName: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'hero_banner',
      button_name: buttonName,
      video_title: videoTitle,
      cast_screen: 'website',
      device_name: this.getDeviceType(),
      page_name: pageName,
      user_login_type: userLoginType,
    };

    this.doFinalPush(o);
  }

  /*
   * 8.1. Video change state
   *  When a user interacts with the videos (Start/Play/Pause/Completed)
   * @pageName: name of page
   * @pageUrl: page url
   * @videoStatus: 'START' | 'PLAY' | 'PAUSE' | 'COMPLETED'
   * @videoTitle: title of video
   * @videoDuration: duration of video
   */
  public videoChangeStateEvent(
    pageName: string,
    pageUrl: string,
    videoStatus: 'START' | 'PLAY' | 'PAUSE' | 'COMPLETED',
    videoTitle: string,
    videoDuration: number,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    if (videoStatus === 'START') {
      // reset video watch percentage when video starts
      this.percentageMarkers = [...GtagService.percentageMarkersOriginalValues];
    }

    const formattedVideoDuration: string = this.convertVideoDuration(videoDuration);

    if (videoStatus === 'PAUSE') {
      if (this.videoProgress > videoDuration) {
        // video has completed
        return;
      }
    }

    const o = {
      event: 'video_interaction',
      video_title: videoTitle,
      video_status: videoStatus,
      video_duration: formattedVideoDuration,
      video_url: pageUrl,
      page_name: pageName,
      user_login_type: userLoginType,
    };

    this.doFinalPush(o);
  }


  private videoProgress: number;
  /*
   * 8.2. Video completion
   *  For 25,50,75 & 100% completion of the videos
   * @pageName: name of page
   * @pageUrl: page url
   * @videoProgress: seconds of video seen
   * @videoTitle: title of video
   * @videoDuration: duration of video
   */
  public videoProgressEvent(
    pageName: string,
    pageUrl: string,
    videoProgress: number,
    videoTitle: string,
    videoDuration: number,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    this.videoProgress = videoProgress;

    const percentage = videoProgress / videoDuration;
    if (this.percentageMarkers.length > 0 && this.percentageMarkers[0] <= percentage) {
      // in case the user scrubs the video, report the latest percentage only
      let percentageAsFloat = 0;
      do {
        percentageAsFloat = this.percentageMarkers.shift();
      } while (percentageAsFloat >= videoProgress);

      const formattedVideoDuration: string = this.convertVideoDuration(videoDuration);

      const o = {
        event: 'video_completion',
        video_title: videoTitle,
        video_progress: `Video Status -  ${percentageAsFloat * 100}%`,
        video_duration: formattedVideoDuration,
        video_url: pageUrl,
        page_name: pageName,
        user_login_type: userLoginType,
      };

      this.doFinalPush(o);
    }
  }

  /*
   * 9. Live section
   * Capture the Successful Cast Function
   * @videoTitle: title of video
   */
  public castSuccessEvent(
    videoTitle: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'cast_success',
      cast_screen: 'website',
      device_name: this.getDeviceType(),
      video_title: videoTitle,
    };

    // this.doFinalPush(o);
  }

  /*
   * 9. Live section
   * Capture the error encountered during cast
   * @videoTitle: title of video
   */
  public castErrorEvent(
    videoTitle: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'cast_error',
      cast_screen: 'website',
      device_name: this.getDeviceType(),
      video_title: videoTitle,
    };

    // this.doFinalPush(o);
  }

  /*
   * 9. Live section
   * When the device on which the cast has to done is found
   * @videoTitle: title of video
   */
  public castingDeviceFoundEvent(
    videoTitle: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'casting_device_found',
      cast_screen: 'website',
      device_name: this.getDeviceType(),
      video_title: videoTitle,
    };

    // this.doFinalPush(o);
  }

  /*
   * 9. Live section
   * When the device on cast is disconnected successfully
   * @videoTitle: title of video
   */
  public castDisconnectSuccessEvent(
    videoTitle: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'cast_disconnect_success',
      cast_screen: 'website',
      device_name: this.getDeviceType(),
      video_title: videoTitle,
    };

    // this.doFinalPush(o);
  }

  /*
   * 9. Live section
   * When the device on cast failed while disconnecting
   * @videoTitle: title of video
   */
  public castDisconnectFailEvent(
    videoTitle: string,
    userLoginType: 'GUEST' | 'EMAIL' = this.cacheUserType.userLoginType,
  ) {
    const o = {
      event: 'cast_disconnect_failed',
      cast_screen: 'website',
      device_name: this.getDeviceType(),
      video_title: videoTitle,
    };

    // this.doFinalPush(o);
  }

  private convertVideoDuration(videoDuration: number): string {
    const t = new Date(videoDuration * 1000);

    return [
      this.padTime(t.getUTCHours()),
      this.padTime(t.getUTCMinutes()),
      this.padTime(t.getUTCSeconds()),
    ].join(':');
  }

  private padTime(s: number): string {
    return s.toString().padStart(2, '0');
  }

  private getDeviceType(): string {
    const device = this.uaParser.getDevice();

    let deviceType = device.type;
    if (!deviceType) {
      deviceType = (navigator.maxTouchPoints === 0)
        ? 'desktop'
        : 'tablet';
    }

    return deviceType;
  }

  private getCurrentFilter(
    latestFilters: { [s: string]: string } = {},
  ): string {
    let currentFilter: string = '';
    Object.keys(latestFilters)
      .forEach((key: string) => {
        currentFilter = `${key} - ${latestFilters[key]}`;
      });

    return currentFilter;
  }

  private latestOptaResultsFilters: { [s: string]: string } = {};
  private backupOptaResultsFilters(
    latestFilters: { [s: string]: string },
  ) {
    this.latestOptaResultsFilters = {
      ...this.latestOptaResultsFilters,
      ...latestFilters,
    };
  }

  private streamPlayFixturesFilters: { [s: string]: string } = {};
  private fixturesFilters(
    latestFilters: { [s: string]: string },
  ) {
    this.streamPlayFixturesFilters = {
      ...this.streamPlayFixturesFilters,
      ...latestFilters,
    };
  }

  private latestVideoFilter: { [s: string]: string } = {};
  private backupVideoResultsFilters(
    latestFilters: { [s: string]: string },
  ) {
    this.latestVideoFilter = {
      ...this.latestVideoFilter,
      ...latestFilters,
    };
  }

  private doFinalPush(o) {
    if (environment.google.tagManager && dataLayer) {
      dataLayer.push(o);
    } else {
      console.log(o);
    }
  }

}
