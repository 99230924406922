import { FooterData } from '../types/wordpress/layout/footer.type';
import {WpCommonFields, WpCookieMessage} from '../types/wordpress/components/wp-common-fields.type';

export class WordpressUtils {
  public static extractCookieDetails(commonData: WpCommonFields): WpCookieMessage {
    const { cookie } = commonData['common-fields'];

    return cookie;
  }

  public static extractFooterData(commonData: WpCommonFields): FooterData {
    const { social_icons, footer_links, sponsors, copyright } = commonData['common-fields'];

    return {
      social_icons,
      footer_links,
      sponsors,
      copyright
    };
  }
}
