export const environment = {
  production: false,

  playlistPageSize: 24,
  generalContentPageSize: 24,
  fixtureContentPageSize: 22,

  google: {
    analyticsId: '',
    secondAnalyticsId: '',
    floodLightId: '',
    tagManager: '',
  },

  backendKsApi: 'https://staging.auth.faplayer.streamamg.com/b2c/ksession',
  backendFancodeApi: 'https://staging.auth.faplayer.streamamg.com/b2c/fancode',

  b2c: {
    authUrl: 'https://b2cppthefa.b2clogin.com',
    tenant: 'b2cppthefa.onmicrosoft.com',
    clientID: 'ceca6ad1-e1c2-4314-8ee9-c90f3e256be6',
    redirectUri: window.location.origin, // 'https://dev-faplayer.thefa.com',
    FAAppToken: 'FAP',
    b2cPolicies_names: {
      signInPolicy: 'B2C_1A_SIGNUP_SIGNINACTDYN', // Policy Name for Sign in - Refer to issued policy name
      signUpPolicy: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVER', // Policy Name for SignUp Refer to Issued Policy Name.
      passwordResetPolicy: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
      forgottenEmailPolicy: 'B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAIL', // SignIn and Recovery Using FAN Code policy
      dontKnowMyFanPolicy: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVER', // Policy for Account Recovery
    },
  },

  favTeams: {
    favTeamsBaseUrl: 'https://hcdeapimngt1.azure-api.net/ott.thefa.com/api/TheFA.OTT',
    header: {
      'Ocp-Apim-Subscription-Key': 'a35cb60c23474f0c93008233cde07671',
    },
  },

  kaltura: {
    kalturaBaseUrl: 'https://open.http.mp.streamamg.com',
    partnerId: '3001343',
    uiConfId: '30027356'
  },

  individualRegistration: {
    url: 'https://account.thefa.com',
    url2: 'https://www.thefa.com',
    source: 'ott',
    device: 'web',
    signUpRoute: '/accounts/signup',
    signOutRoute: '/account/signout'
  },

  wordpress: {
    api: 'https://wp-devthefaplayer.streamamg.com/wp-json/wpa/v1',
    pages: {
      home: '/section?page=home-page',
      fixtures: '/section?page=fixtures-page',
      teamHighlights: '/section?page=highlights-page',
      boxsets: '/section?page=boxsets-page',
      archive: '/section?page=archive-page',
      support: '/section?page=support-page',
      liveplayer: '/section?page=video-page',
      player: '/section?page=video-page',
      score: '/section?page=score-page',
    },
    commonData: '/common_field',
    seasons: '/season',
    competitions: '/competition',
    teams: '/team',
  }
};
