import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnInit, Renderer2 } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Config } from './app.config.js';
import { WINDOW } from './core/providers/window-provider';
import { AppViewService } from './core/services/app-view.service';
import { B2cService } from './core/services/b2c/b2c.service';
import { B2CAccount } from './core/services/b2c/b2c.type.js';
import { FaFavTeamsService } from './core/services/fa-favteams.service';
import { GtagService } from './core/services/gtag.service';
import { ModalService } from './core/services/modal.service';
import { WordpressService } from './core/services/wordpress.service';
import { FavTeam } from './core/types/FavTeamsApiResponse.type';
import {
  WpCommonFields,
  WpCookieMessage,
  WpInstallPrompt,
} from './core/types/wordpress/components/wp-common-fields.type';
import { FooterData } from './core/types/wordpress/layout/footer.type';
import { WordpressUtils } from './core/utils/wordpress-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private static onboardingCompleteId: string = 'onboarding-complete';

  title = 'The FA Player';

  public wpFooterData: FooterData;
  public wpCookieMessage: WpCookieMessage;

  public installPrompt: WpInstallPrompt;

  private user: B2CAccount;

  public appReady: boolean;

  private static setScreenSizesOnRoot(setWhat) {
    if (setWhat !== 'w') {
      const vh = window.innerHeight;
      // Then we set the value in the --vh custom property to the root of the document
      document.documentElement.style.setProperty('--vh', vh + 'px');
      if (vh === 0) {
        setTimeout(() => {
          AppComponent.setScreenSizesOnRoot('h');
        }, 333);
      }
    }
    // First we get the viewport height
    if (setWhat !== 'h') {
      const vw = document.body.clientWidth;
      document.documentElement.style.setProperty('--vw', vw + 'px');
      if (vw === 0) {
        setTimeout(() => {
          AppComponent.setScreenSizesOnRoot('w');
        }, 333);
      }
    }
  }

  constructor(
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window,
    private wordpressService: WordpressService,
    private modalService: ModalService,
    private appViewService: AppViewService,
    private b2cService: B2cService,
    private faFavTeamsService: FaFavTeamsService,
    private gtagService: GtagService,
    private renderer: Renderer2,
    private meta: Meta,
    private activatedRoute: ActivatedRoute,
  ) {
    this.wpFooterData = null;
    // if we're booting the application with the b2c route
    // it means that the user has cancelled some request
    if (window.location.href.indexOf('/b2c#/') > -1) {
      this.b2cService.cancelRequest();
    }
  }

  ngOnInit(): void {

    this.activatedRoute.fragment.subscribe((fragment: string) => {

      if (this.b2cService.handleUrlFragment(fragment)) {
        // B2C no redirect proceed...

        AppComponent.setScreenSizesOnRoot('both');

        // Floodlight tag - Landing page only.
        this.sendGoogleFloodLightData();

        let currentRoute = '';
        this.router.events
          .pipe(filter(event => event instanceof NavigationStart || event instanceof NavigationEnd))
          .subscribe((event: NavigationStart | NavigationEnd) => {
            const route = event.url.split('/')[1];
            const fragment = event.url.split('#')[1];
            if (fragment && fragment.length > 1 && route && route !== 'b2c#') {
              this.router.navigate(['/b2c'], {fragment: fragment});
            } else if (event instanceof NavigationStart) {
              if (currentRoute === event.url.split('/')[1]) {
                this.renderer.addClass(this.document.body.parentElement, 'same-page-navigation');
              } else {
                this.renderer.removeClass(this.document.body.parentElement, 'same-page-navigation');
              }
            } else {
              currentRoute = event.urlAfterRedirects.split('/')[1];
              this.setPageAttribute(event, 'urlAfterRedirects', 'data-page');
              this.sendGoogleAnalyticsData();
            }
          });

        this.wordpressService
          .getCommonData()
          .subscribe(wpCommonData => {
            if (wpCommonData) {
              this.wpFooterData = WordpressUtils.extractFooterData(wpCommonData);
              this.wpCookieMessage = WordpressUtils.extractCookieDetails(wpCommonData);

              this.initNativeAppInstallPrompt(wpCommonData);

              this.appReady = true;
            }
          });

        this.b2cService
          .getUserObservable()
          .subscribe({
            next: (user: B2CAccount) => {
              this.user = user;

              if (this.user) {
                const fanCode = this.b2cService.getFanCode();
                this.gtagService.setUserType('EMAIL', fanCode);
              } else {
                this.gtagService.setUserType('GUEST', '');
              }
              this.sendGoogleAnalyticsData();
            },
            error: () => {

            },
          });

        this.faFavTeamsService
          .getFavTeamsObservable()
          .subscribe({
            next: (favTeams: FavTeam[]) => {
              if (favTeams) {
                this.startOnBoarding(favTeams);
              }
            },
            error: () => {

            },
          });
      }
    });
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    let target: HTMLElement = event.target;
    let reportClick = false;

    do {

      if (target.localName === 'a') {
        const className = target.className.toLowerCase();
        reportClick = className.indexOf('tagged-link') > -1;
      }

      if (!reportClick) {
        target = target.parentElement;
      }
    } while (target !== null && reportClick === false);

    if (reportClick) {
      const rawUrl = window.location.pathname;
      const targetPath = target.getAttribute('href');
      let linkText = target.textContent;
      let pageTitle: string = this.generatePageTitleFromRawUrl(rawUrl.substr(1));
      const pageSection = target.getAttribute('aria-details');

      try {
        linkText = target.getAttribute('aria-label') || target.querySelector('.title').getAttribute('title');
      } catch (ex) {
      }

      if (pageTitle.trim().startsWith('video -')) {
        try {
          pageTitle = 'video - ' + document.querySelector('#player-container h3.title').getAttribute('title');
        } catch (ex) {
        }
      }

      let navigationTarget: 'INTERNAL' | 'EXTERNAL';
      const ariaTarget = (target.getAttribute('aria-target') || '').toLocaleLowerCase();
      switch (ariaTarget) {
        case 'internal': {
          navigationTarget = 'INTERNAL';
          break;
        }
        case 'external': {
          navigationTarget = 'EXTERNAL';
          break;
        }
        case 'cta': {
          navigationTarget = 'INTERNAL';
          break;
        }
        default: {
          navigationTarget = (targetPath === null || targetPath[0] === '/')
            ? 'INTERNAL'
            : 'EXTERNAL';
        }
      }

      this.gtagService.userInteractsLinkEvent(
        pageTitle,
        pageSection,
        linkText,
        navigationTarget,
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    AppComponent.setScreenSizesOnRoot('w');
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    setTimeout(() => {
      AppComponent.setScreenSizesOnRoot('h');
    }, 200);
  }

  private getOnBoardingObject(): any {
    return JSON.parse(localStorage.getItem(AppComponent.onboardingCompleteId) || '{}');
  }

  private setOnBoardingObject(onboardingObject: any): any {
    localStorage.setItem(AppComponent.onboardingCompleteId, JSON.stringify(onboardingObject));
  }

  /**
   * The concept behind this is to save the date when the user first saw the onboarding modal
   * This way, if we need to run the on-boarding process again,
   * we can compare the current date against the saved date and decide if we want to show it
   * @param favTeams
   */
  private startOnBoarding(favTeams: FavTeam[]) {
    const onboardingComplete: any = this.getOnBoardingObject();
    const fanCode: string = this.b2cService.getFanCode();

    if (!!fanCode) {
      if (favTeams.length === 0) {
        this.modalService.openModal('fa-fav-teams');

        onboardingComplete[fanCode] = new Date().valueOf();
        this.setOnBoardingObject(onboardingComplete);
      }
    }
  }

  private initNativeAppInstallPrompt(commonData: WpCommonFields) {
    this.installPrompt = commonData['common-fields'].install_prompt;
  }

  private setPageAttribute(event: RouterEvent, eventUrlKey: string, htmlAttribute: string): void {
    if (event[eventUrlKey] !== '/') {
      const urlTree = this.router.parseUrl(event[eventUrlKey]);
      const page = urlTree.root.children.primary.segments.map(it => it.path).join('/');
      this.renderer.setAttribute(this.document.body.parentElement, htmlAttribute, page);
    }
  }

  private generatePageTitleFromRawUrl(rawUrl: string = ''): string {
    return rawUrl
      .split('-').join(' ')
      .split('/').join(' - ')
      .split('?')[0];
  }

  private sendGoogleAnalyticsData(): void {
    const rawUrl: string = window.location.href.replace(window.location.origin, '').substring(1);

    const bannedUrl: boolean = (
      rawUrl.startsWith(Config.video.path + '/') ||
      rawUrl.startsWith(Config.eventPreview.path + '/') ||
      rawUrl.startsWith(Config.fixturePreview.path + '/') ||
      false
    );

    if (!bannedUrl) {
      const pageTitle: string = this.generatePageTitleFromRawUrl(rawUrl);
      const route: string = rawUrl.split('/')[0];

      const pathConfig = Config.list
        .find((pathConfig) => {
          return pathConfig.path === route;
        });

      if (pathConfig) {
        this.gtagService.pageNavigationEvent(
          pageTitle,
          rawUrl,
          pathConfig.moduleName,
        );
      }
    }
    /*
    if (environment.google.analyticsId) {
      (this.window as any).gtag('config', environment.google.analyticsId, {
        page_path: event.urlAfterRedirects
      });
    }
    if (environment.google.secondAnalyticsId) {
      (this.window as any).gtag('config', environment.google.secondAnalyticsId, {
        page_path: event.urlAfterRedirects
      });
    }
    */
  }

  private sendGoogleFloodLightData(): void {
    /*
    if (environment.google.floodLightId) {
      (this.window as any).gtag('event', 'conversion', {
        allow_custom_scripts: true,
        send_to: `${environment.google.floodLightId}/thefa0/2019t0+standard`
      });
    }
    */
  }
}
