import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { WpCompetition, WpCompetitionSeason } from '../types/wordpress/components/wp-competition.type';
import { WpSeason } from '../types/wordpress/components/wp-season.type';
import { WpTeam } from '../types/wordpress/components/wp-team.type';

@Injectable({
  providedIn: 'root'
})
export class WordpressCompetitionService {
  private competitionDataBS: BehaviorSubject<Map<string, WpCompetition>> = new BehaviorSubject(null);

  private competitionsCache: { [s: string]: WpCompetition };
  private seasonsCache: { [s: string]: WpSeason };
  private teamsCache: { [s: string]: WpTeam };

  private cache: { [url: string]: BehaviorSubject<any> };

  private static generatePageUrl(path: string): string {
    return `${environment.wordpress.api}${path}`;
  }

  private static getPageUrl(key: string): string {
    return `${environment.wordpress.api}${environment.wordpress.pages[key]}`;
  }

  private static getCommonDataUrl(): string {
    return `${environment.wordpress.api}${environment.wordpress.commonData}`;
  }

  constructor(
    private http: HttpClient,
  ) {
    this.cache = {};
  }

  private static getCompetitionDataUrl(): string {
    return `${environment.wordpress.api}${environment.wordpress.competitions}`;
  }

  private static getSeasonDataUrl(): string {
    return `${environment.wordpress.api}${environment.wordpress.seasons}`;
  }

  private static getTeamDataUrl(): string {
    return `${environment.wordpress.api}${environment.wordpress.teams}`;
  }

  private getData<T>(url: string): Observable<T> {
    if (!this.cache[url]) {
      this.cache[url] = new BehaviorSubject<T>(null);

      this.http.get(url).subscribe(value => {
        this.cache[url].next(value);
      });
    }

    return this.cache[url].asObservable();
  }

  private afterGetCompetitionAndTeamData() {
    if (this.teamsCache && this.seasonsCache && this.competitionsCache) {
      const wpCompetitionMap: Map<string, WpCompetition> = new Map();
      const wpSeasonMap: Map<string, WpSeason> = new Map();
      const wpTeamMap: Map<string, WpTeam> = new Map();

      Object
        .keys(this.teamsCache)
        .filter((key: string) => {
          const value = this.teamsCache[key];

          return value.cloudmatrix_id || value.streamplay_id || value.opta_id;
        })
        .sort()
        .forEach((key: string) => {
          const value = this.teamsCache[key];

          wpTeamMap.set(value.id, value);
        });

      Object
        .keys(this.seasonsCache)
        .sort()
        .forEach((key: string) => {
          const value = this.seasonsCache[key];

          wpSeasonMap.set(value.id, value);
        });

      Object
        .keys(this.competitionsCache)
        .sort()
        .filter((competition) => this.competitionsCache[competition].enabled)
        .forEach((key: string) => {
          const competition = this.competitionsCache[key];

          const teams = competition.teams as Array<{ team_id: string; }>;
          competition.teamCollection = [] as Array<WpTeam>;
          if (teams) {
            teams
              .forEach((value: { team_id: string; }, i: number) => {
                const teamId = value.team_id;
                const team = wpTeamMap.get(teamId);

                competition.teamCollection.push(team);
              });
          } else {
            competition.teams = [];
            wpTeamMap
              .forEach((team: WpTeam, key: string, map: Map<string, WpTeam>) => {
                competition.teams.push({ team_id: team.id, });
                competition.teamCollection.push(team);
              });
          }

          const seasons = competition.seasons as Array<WpCompetitionSeason>;
          competition.seasonCollection = [] as Array<WpSeason>;
          if (seasons) {
            seasons
              .forEach((value: WpCompetitionSeason, i: number) => {
                const seasonId = value.season_id;
                const season = wpSeasonMap.get(seasonId);

                competition.seasonCollection.push({
                  ...season,
                  rounds: value.rounds,
                  months: value.months,
                } as WpSeason);
              });
          } else {
            competition.seasons = [];
            wpSeasonMap
              .forEach((season: WpSeason, key: string, map: Map<string, WpTeam>) => {
                competition.seasons.push({ season_id: season.id, });
                competition.seasonCollection.push(season);
              });
          }

          competition.slug = competition.slug.toLocaleLowerCase();

          wpCompetitionMap.set(competition.id, competition);
        });

      this.competitionDataBS.next(wpCompetitionMap);
    }
  }

  public getTeamData(): Observable<any> {
    return this.getData<any>(WordpressCompetitionService.getTeamDataUrl());
  }

  public getSeasonData(): Observable<any> {
    return this.getData<any>(WordpressCompetitionService.getSeasonDataUrl());
  }

  public getCompetitionData(): Observable<any> {
    this.getTeamData()
      .subscribe((teams) => {
        if (teams) {
          this.teamsCache = teams;

          this.afterGetCompetitionAndTeamData();
        }
      });

    this.getSeasonData()
      .subscribe((seasons) => {
        if (seasons) {
          this.seasonsCache = seasons;

          this.afterGetCompetitionAndTeamData();
        }
      });

    this.getData<any>(WordpressCompetitionService.getCompetitionDataUrl())
      .subscribe((competitions) => {
        if (competitions) {
          this.competitionsCache = competitions;

          this.afterGetCompetitionAndTeamData();
        }
      });

    return this.competitionDataBS.asObservable();
  }

}
