import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import MobileDetect from 'mobile-detect';
import jwt_decode from 'jwt-decode';

if (environment.production) {
  enableProdMode();
}

/*
if (environment.google.analyticsId || environment.google.floodLightId || environment.google.secondAnalyticsId) {
  (window as any).gtag('js', new Date());

  if (environment.google.analyticsId) {
    (window as any).gtag('config', environment.google.analyticsId);
  }
  if (environment.google.secondAnalyticsId) {
    (window as any).gtag('config', environment.google.secondAnalyticsId);
  }

  if (environment.google.floodLightId) {
    (window as any).gtag('config', environment.google.floodLightId);
  }
}
*/

const b2cParams = new URLSearchParams(window.location.hash);
if (b2cParams.get('id_token')) {

  const id_tokenDecoded = jwt_decode(b2cParams.get('id_token'));

  if (id_tokenDecoded['acr'] === 'b2c_1a_pwresetcustomotppff_str') {
    //reset password done --> redirect the user to login page

    history.replaceState(null, null, `${window.location.origin}?doLogin=1`);
  }
}


const mobileDetect = new MobileDetect(window.navigator.userAgent);
(window as any).isMobileDevice = mobileDetect.mobile();
if ((window as any).isMobileDevice) {
  document.body.classList.add('mobile-device');
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
