import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlUtils } from 'amg-fe-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FavTeam } from '../types/FavTeamsApiResponse.type';
import { WpCommonFields } from '../types/wordpress/components/wp-common-fields.type';
import { WpCompetition } from '../types/wordpress/components/wp-competition.type';
import { WpPage } from '../types/wordpress/components/wp-page.type';
import { ArchivePage } from '../types/wordpress/pages/archive-page.type';
import { FixturesPage } from '../types/wordpress/pages/fixtures-page.type';
import { HomePage } from '../types/wordpress/pages/home-page.type';
import { ScoresPage } from '../types/wordpress/pages/scores-page.type';
import { SupportPage } from '../types/wordpress/pages/support-page.type';
import { VideoPage } from '../types/wordpress/pages/video-page.type';
import { FaFavTeamsService } from './fa-favteams.service';
import { WordpressCompetitionService } from './wordpress.competition.service';

@Injectable({
  providedIn: 'root'
})
export class WordpressService {
  public static myTeamsCarousel: string = 'carousel-0';

  private cache: { [url: string]: BehaviorSubject<any> };

  private pristineHome: HomePage;
  private favTeams: FavTeam[];

  private static generatePageUrl(path: string): string {
    return `${environment.wordpress.api}${path}`;
  }

  private static getPageUrl(key: string): string {
    return `${environment.wordpress.api}${environment.wordpress.pages[key]}`;
  }

  private static getCommonDataUrl(): string {
    return `${environment.wordpress.api}${environment.wordpress.commonData}`;
  }

  constructor(
    private http: HttpClient,
    private faFavTeamsService: FaFavTeamsService,
    private wordpressCompetitionService: WordpressCompetitionService,
  ) {
    this.cache = {};

    this.faFavTeamsService
      .getFavTeamsObservable()
      .subscribe((favTeams: FavTeam[]) => {
        this.favTeams = favTeams || [];

        this.reprocessHomepageData();
      });
  }

  /**
   * Grabs the data from the memory cache if available, else it will trigger a call and retrieves the data.
   * @param pagePath WP page path
   */
  public getPageData<D>(pagePath: string): Observable<D> {
    switch (pagePath) {
      case environment.wordpress.pages.home: {
        return this.getHomePage() as Observable<any>;
        break;
      }
      default: {
        return this.getData<D>(WordpressService.generatePageUrl(pagePath));
        break;
      }
    }
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getCommonData(): Observable<WpCommonFields> {
    return this.getData<WpCommonFields>(WordpressService.getCommonDataUrl());
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getCompetitionData(): Observable<Map<string, WpCompetition>> {
    return this.wordpressCompetitionService.getCompetitionData();
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getTeamData(): Observable<any> {
    return this.wordpressCompetitionService.getTeamData();
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getHomePage(): Observable<HomePage> {
    const url = this.getHomePageUrl();

    if (!this.cache[url]) {
      this.cache[url] = new BehaviorSubject<HomePage>(null);

      this.http
        .get<HomePage>(url)
        .subscribe(value => {
          this.pristineHome = value;

          this.reprocessHomepageData();
        });
    }

    return this.cache[url].asObservable();
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getFixturesPage(): Observable<FixturesPage> {
    return this.getData<FixturesPage>(WordpressService.getPageUrl('fixtures'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getTeamHighlightsPage(): Observable<{ [t: string]: WpPage }> {
    return this.getData<{ [t: string]: WpPage }>(WordpressService.getPageUrl('teamHighlights'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getBoxsetsPage(): Observable<{ [t: string]: WpPage }> {
    return this.getData<{ [t: string]: WpPage }>(WordpressService.getPageUrl('boxsets'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getArchivePage(): Observable<ArchivePage> {
    return this.getData<ArchivePage>(WordpressService.getPageUrl('archive'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getSupportPage(): Observable<SupportPage> {
    return this.getData<SupportPage>(WordpressService.getPageUrl('support'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getLivePlayerPage(): Observable<VideoPage> {
    return this.getData<VideoPage>(WordpressService.getPageUrl('liveplayer'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getPlayerPage(): Observable<VideoPage> {
    return this.getData<VideoPage>(WordpressService.getPageUrl('player'));
  }

  /**
   * Any subsequent calls are retrieved from the memory Cache.
   */
  public getScoresPage(): Observable<ScoresPage> {
    return this.getData<ScoresPage>(WordpressService.getPageUrl('score'));
  }

  private getData<T>(url: string): Observable<T> {
    if (!this.cache[url]) {
      this.cache[url] = new BehaviorSubject<T>(null);

      this.http.get(url).subscribe(value => {
        this.cache[url].next(value);
      });
    }

    return this.cache[url].asObservable();
  }

  private getHomePageUrl(): string {
    return WordpressService.getPageUrl('home');
  }

  private reprocessHomepageData() {
    if (this.pristineHome && this.favTeams) {
      const home = { ...this.pristineHome };

      if (this.favTeams.length === 0) {
        delete home[WordpressService.myTeamsCarousel];
      } else {
        const myFavTeamsCarousel: any = home[WordpressService.myTeamsCarousel];


        if (myFavTeamsCarousel && myFavTeamsCarousel.api) {
          if (!myFavTeamsCarousel.api_original) {
            myFavTeamsCarousel.api_original = myFavTeamsCarousel.api;
          }

          const query: Array<string> = new Array();
          this.favTeams
            .forEach((favTeam: FavTeam) => {
              const club_name = favTeam.club_name.split('&').join(' ');
              query.push(`(metaData.teams:${club_name})`);
            });

          const paramsObject: any = UrlUtils.extractUrlParamsFromExistingUrl(myFavTeamsCarousel.api_original);
          if (paramsObject.query) {
            paramsObject.query = `(${paramsObject.query}AND(${query.join('OR')}))`;
          } else {
            paramsObject.query = `(${query.join('OR')})`;
          }
          const paramsString = UrlUtils.generateUrlParameters(paramsObject);

          myFavTeamsCarousel.api = myFavTeamsCarousel.api_original.split('?')[0] + '?' + paramsString;
        }
      }

      this.cache[this.getHomePageUrl()].next(home);
    }
  }
}
