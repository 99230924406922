import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { MsalAngularConfiguration, MsalInterceptor, MsalModule, MsalService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR } from '@azure/msal-angular';

import { Configuration } from 'msal';

import { CookieService } from 'ngx-cookie-service';

import { intersectionObserverPreset, LazyLoadImageModule } from 'ng-lazyload-image';

import { WINDOW_PROVIDERS } from './core/providers/window-provider';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/components/header/header.component';
import { PrimaryHeaderComponent } from './core/components/header/primary-header/primary-header.component';
import { FaSecondaryHeaderComponent } from './core/components/header/fa-secondary-header/fa-secondary-header.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { CookieNotificationComponent } from './core/components/cookie-notification/cookie-notification.component';
import { ModalsComponent } from './core/components/modals/fa-fav-team/modals.component';
import { FaFavTeamModalComponent } from './core/components/modals/fa-fav-team/fa-fav-team-modal/fa-fav-team-modal.component';
import { SharedModule } from './modules/shared/shared.module';
import { SmartBannerModule } from './modules/smart-banner/smart-banner.module';

import { msalConfig, msalAngularConfig } from './core/services/b2c/b2c.config';

export function MSALConfigFactory(): Configuration {
  return msalConfig;
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return msalAngularConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    ModalsComponent,
    PrimaryHeaderComponent,
    FaSecondaryHeaderComponent,
    FaFavTeamModalComponent,
    CookieNotificationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    LazyLoadImageModule.forRoot({
      preset: intersectionObserverPreset // <-- tell LazyLoadImage that you want to use IntersectionObserver
    }),
    FormsModule,
    NgbModalModule,
    MsalModule,
    SmartBannerModule,
    // Disable Service worker as we don't want to show 'Add to Home screen' due to Native Mobile applications
    // ServiceWorkerModule.register('ngsw-worker.js',
    //   { enabled: environment.production, registrationStrategy: 'registerImmediately' })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService,
    WINDOW_PROVIDERS,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
