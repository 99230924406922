import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { retry } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { environment } from '../../../environments/environment';
import { FavTeam, FavTeamsApiResponse } from '../types/FavTeamsApiResponse.type';
import { B2cService } from './b2c/b2c.service';

@Injectable({
  providedIn: 'root'
})
export class FaFavTeamsService {
  private static defaultSetFavTeamDataHttpOptions: any = {
    headers: new HttpHeaders({
      ...environment.favTeams.header,
      'Content-Type': 'application/json',
    }),
  };

  private userFaveTeamsRetrievalSubscription: Subscription;

  private favTeamsBS: BehaviorSubject<FavTeam[]>;
  private favTeamsSetBS: BehaviorSubject<number>;

  private fanCode: string;

  private favTeamsSetSubscription: Subscription;
  private subSink: SubSink;

  constructor(
    private httpClient: HttpClient,
    private b2cService: B2cService,
  ) {
    this.subSink = new SubSink();

    this.init();
  }

  public getFavTeamsObservable(): Observable<FavTeam[]> {
    return this.favTeamsBS.asObservable();
  }

  public saveFavTeams(favTeams: FavTeam[]): Observable<number> {
    if (!!this.fanCode) {
      if (this.favTeamsSetSubscription) {
        this.favTeamsSetSubscription.unsubscribe();
      }

      const fc = parseInt(this.fanCode, 10);

      const httpBody: FavTeamsApiResponse = {
        uid: fc,
        fav_clubs: [...favTeams],
      };

      this.favTeamsSetBS.next(0);

      this.favTeamsSetSubscription = this.httpClient
        .post<FavTeamsApiResponse>(
          `${environment.favTeams.favTeamsBaseUrl}/SetFavTeamData`,
          httpBody,
          FaFavTeamsService.defaultSetFavTeamDataHttpOptions,
        )
        .pipe(retry(3))
        .subscribe({
          next: (response: any) => {
            if (response && typeof(response) === 'string' && (response as string).toUpperCase() === 'SUCCESS') {
              this.processFavTeamsResponse(httpBody);
              this.favTeamsSetBS.next(1);
            } else {
              this.resetServiceData();
              this.favTeamsSetBS.error(400);
            }
          },
          error: (httpErrorResponse: HttpErrorResponse) => {
            this.resetServiceData();
            this.favTeamsSetBS.error(httpErrorResponse.status);
          }
        });
    }

    return this.favTeamsSetBS.asObservable();
  }

  private init(): void {
    this.favTeamsBS = new BehaviorSubject(undefined);
    this.favTeamsSetBS = new BehaviorSubject(0);

    this.subscribeToFanCodeChanges();
  }

  private subscribeToFanCodeChanges(): void {
    this.subSink.sink = this.b2cService.getFanCodeObservable()
      .subscribe((fanCode: string) => {
        this.fanCode = fanCode;

        this.triggerUserFavTeamsRetrieval();
      });
  }

  private triggerUserFavTeamsRetrieval(): void {
    if (!!this.fanCode) {
      if (this.userFaveTeamsRetrievalSubscription) {
        this.userFaveTeamsRetrievalSubscription.unsubscribe();
      }

      this.userFaveTeamsRetrievalSubscription = this.httpClient
        .get<FavTeamsApiResponse>(`${environment.favTeams.favTeamsBaseUrl}/GetFavTeamData`, {
          headers: environment.favTeams.header,
          params: {
            fanCode: this.fanCode,
          }
        })
        .pipe(retry(3))
        .subscribe({
          next: (favTeamsApiResponse) => {
            if (favTeamsApiResponse && favTeamsApiResponse.uid > -1) {
              this.processFavTeamsResponse(favTeamsApiResponse);
            } else {
              this.resetServiceData();
            }
          },
          error: (httpErrorResponse: HttpErrorResponse) => {
            this.resetServiceData();
          }
        });

    }
  }

  private resetServiceData(): void {
    this.favTeamsBS.next([]);
  }

  private processFavTeamsResponse(favTeamsApiResponse: FavTeamsApiResponse) {
    this.favTeamsBS.next(favTeamsApiResponse.fav_clubs);
  }

}
